import { background, extendTheme } from '@chakra-ui/react';

import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const theme = extendTheme({
	// *FONTS
	fonts: { heading: 'Montserrat', body: 'Montserrat' },

	colors: {
		primary: {
			500: '#4f6ac1',
		},
	},

	components: {
		Button: {
			variants: {
				solid: {
					colorScheme: 'blue',
					color: '#fff',
					bg: '#4f6ac1',
					padding: '16px',
					fontWeight: '600',
					width: '135px',
					__hover: {
						background: '#4f6ac1 !important',
					},
				},

				outline: {
					color: '#fff',
					padding: '16px',
					width: '135px',
					__hover: {
						color: '#4f6ac1',
					},
				},
			},
		},

		Link: {
			baseStyle: {
				_hover: {
					color: '#4f6ac1',
				},
			},
		},
	},
});

export default theme;
